import { useRouter } from 'next/router';

import { usePageRobotAndRoutine } from '@sbrc/hooks';
import { LINK_TO_ROBOT_LIST_PAGE } from '@sbrc/utils';

import FullPageLoader from '../FullPageLoader';
import RobotNotFoundError from '../visualizer-view-shared/RobotNotFoundError';

import MoveRobotView from './MoveRobotView';

export default function MoveRobotViewLayout() {
  const { push } = useRouter();

  const { robot, routine } = usePageRobotAndRoutine();

  if (robot === undefined) {
    return <FullPageLoader label="Loading move page" />;
  }

  if (robot === null) {
    return <RobotNotFoundError />;
  }

  return (
    <MoveRobotView
      onReturn={() => push(LINK_TO_ROBOT_LIST_PAGE)}
      robot={robot}
      routine={routine}
    />
  );
}
