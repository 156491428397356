import { useRoutineList } from '@sbrc/hooks';

import MoveRobotPage from './move';
import WelcomePage from './robots/welcome';

export default function HomePage() {
  const routines = useRoutineList();

  if (routines === undefined || routines.length > 0) {
    return <MoveRobotPage />;
  }

  return <WelcomePage />;
}
