import PrivatePageLayout from '@sbrc/components/layouts/PrivatePageLayout';
import WelcomeViewLayout from '@sbrc/components/welcome/WelcomeViewLayout';

export default function WelcomePage() {
  return (
    <PrivatePageLayout title="Welcome">
      <WelcomeViewLayout />
    </PrivatePageLayout>
  );
}
