import MoveRobotViewLayout from '@sbrc/components/robots-move/MoveRobotViewLayout';
import PrivatePage from '@sbrc/components/user-sessions/PrivatePage';

export default function MoveRobotPage() {
  return (
    <PrivatePage>
      <MoveRobotViewLayout />
    </PrivatePage>
  );
}
