import { usePageRobotAndRoutine } from '@sbrc/hooks';

import FullPageLoader from '../FullPageLoader';
import RobotNotFoundError from '../visualizer-view-shared/RobotNotFoundError';

import WelcomeView from './WelcomeView';

export default function WelcomeViewLayout() {
  const { robot } = usePageRobotAndRoutine();

  if (robot === undefined) {
    return <FullPageLoader label="Loading move page" />;
  }

  if (robot === null) {
    return <RobotNotFoundError />;
  }

  return <WelcomeView robot={robot} />;
}
