import Head from 'next/head';
import { useMemo } from 'react';

import { BackgroundCover } from '@sb/ui/components';
import { usePageRobotAndRoutine } from '@sbrc/hooks';
import { getPageTitle } from '@sbrc/utils';

import AppHeader from '../header/AppHeader';
import type { Page } from '../header/AppHeaderNav';
import AppHeaderNav from '../header/AppHeaderNav';
import PrivatePage from '../user-sessions/PrivatePage';

import styles from './PrivatePageLayout.module.css';

interface PrivatePageLayoutProps {
  children: React.ReactNode;
  title: string;
  activePage?: Page;
}

/**
 * Shared layout for private pages (routes requiring authentication).
 */
const PrivatePageLayout = ({
  activePage,
  children,
  title,
}: PrivatePageLayoutProps) => {
  const pageTitle = useMemo(() => getPageTitle(title), [title]);

  const { robot } = usePageRobotAndRoutine();

  return (
    <PrivatePage>
      <BackgroundCover className={styles.backgroundCover}>
        <Head>
          <title>{pageTitle}</title>
        </Head>

        {robot && (
          <AppHeader robot={robot}>
            <AppHeaderNav robotID={robot.id} activePage={activePage} />
          </AppHeader>
        )}

        <div className={styles.container}>{children}</div>
      </BackgroundCover>
    </PrivatePage>
  );
};

export default PrivatePageLayout;
